import React from 'react';
import Layout from '@/components/layout';
import Icon from 'supercons';
import { graphql, Link } from 'gatsby';
import { DateTime } from 'luxon';
import { formatDate } from '@/model/format-date';

interface QueryProps {
  markdownRemark: {
    frontmatter: {
      title: string;
      start: string;
      end?: string;
      price?: number;
      teacher?: string;
    };
    fields: {
      slug: string;
    };
    html: string;
  };
  previous: RelatedPost;
  next: RelatedPost;
}

interface RelatedPost {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

DateTime.local().setZone('America/Los Angeles');

export default function Post({ data }: { data: QueryProps }) {
  const post = data.markdownRemark;

  return (
    <Layout>
      <article
        className="py-24 mx-auto"
        itemID="#"
        itemScope
        itemType="http://schema.org/BlogPosting">
        <div className="w-full mx-auto mb-12 text-center md:w-2/3">
          <Link
            to={`/events`}
            className="mb-4 block text-xs font-title text-clay">
            Events + Classes
          </Link>

          <h1
            className="mb-3 text-4xl font-bold text-gray-900 md:leading-tight md:text-5xl"
            itemProp="headline"
            title={post.frontmatter.title}>
            {post.frontmatter.title}
          </h1>
        </div>

        <div className="mx-auto prose mb-4 max-w-none">
          <p className="tracking-wide">
            <div className="text-xl mb-2">
              <span className="font-semibold">When: </span><span>{getTime()}</span>
            </div>
            <div className="text-xl mb-2">
              <span className="font-semibold">Teacher: </span><span>{post.frontmatter.teacher}</span>
            </div>
            <div className="text-xl ">
              <span className="font-semibold">Price: </span><span>${post.frontmatter.price}</span>
            </div>
          </p>
        </div>

        <div
          className="mx-auto prose max-w-none"
          dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  );

  function getTime() {
    const start = DateTime.fromISO(post.frontmatter.start);
    const end = post.frontmatter.end && DateTime.fromISO(post.frontmatter.end);
    const endsSameDay = end && end.toFormat('dd') === start.toFormat('dd');
    const val = DateTime.fromISO(post.frontmatter.start).toFormat('LLLL dd, yyyy, t');

    if (!end) {
      return val;
    }

    return `${val} to ${endsSameDay ? end.toFormat('t') : end.toFormat('LLLL dd, yyyy, t')}`;
  }
}

// Dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query(
    $slug: String!
    $previousPost: String
    $nextPost: String
  ) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        start
        end
        teacher
        price
      }
      html
    }
    previous: markdownRemark(fields: { slug: { eq: $previousPost } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(fields: { slug: { eq: $nextPost } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
